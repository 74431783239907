import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import Spinner from "../Spinner";
import { isObject } from "lodash";

export const Select = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [dropOpen, setDropOpen] = useState(false);
  const [stop, setStop] = useState(false);
  const [showError, setShowError] = useState(false);
  const [options, setOptions] = useState(props.options || []);
  const [mainOptions, setMainOptions] = useState([]);
  const [selectedMain, setSelectedMain] = useState("");

  const ref = useRef();

  useEffect(() => {
    props.value && setValue(props.value);
    !props.value && setValue("");
  }, [props.value]);

  useEffect(() => {
    const mainOptions = props.options.map((option) => ({
      ...option,
      expanded: false,
    }));
    setOptions(mainOptions);
    setMainOptions(mainOptions);
  }, [props.options]);

  useOutsideClick(ref, () => {
    if (dropOpen && !stop) setDropOpen(false);
    setStop(false);
  });

  const sizeLg = props.size === "lg";
  const sizeMd = props.size === "md" || !props.size;
  const sizeSm = props.size === "sm";

  const labelClasses = classNames("block mb-1", "text-sm font-medium", {
    "text-gray-F900": !showError && !props.loading,
    "text-red-700": props.error && showError,
  });

  const classes = classNames(
    props.className,
    "shadow-sm bg-white",
    "block w-full",
    "border rounded-lg",
    {
      "cursor-text": props.search,
      "p-4 sm:text-md": sizeLg,
      "p-2.5 text-sm": sizeMd,
      "p-2 sm:text-xs": sizeSm,
      "bg-gray-100 cursor-not-allowed focus:ring-gray-300": props.loading,
      "border-gray-300 focus:outline-none focus-visible:ring-1 focus:ring-1 focus-visible:ring-primary-500 focus:ring-primary-500 focus:border-primary-500 text-gray-900 cursor-default":
        !showError && !props.loading,
      "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:outline-2 focus:ring-red-500 focus:border-red-500 focus-visible:ring-red-500 focus-visible:border-red-500":
        props.error && showError,
    }
  );
  const wrapperClasses = classNames("block", props.wrapperClasses);

  const optionClasses = classNames(
    "hover:bg-gray-100",
    { "p-4 sm:text-md": sizeLg },
    { "p-2.5 text-sm": sizeMd },
    { "px-2 py-1 sm:text-xs": sizeSm }
  );

  const iconClasses = classNames("h-4 w-4");

  const Icon = dropOpen ? ChevronUpIcon : ChevronDownIcon;

  function handleError() {
    if (props.error) {
      setTimeout(() => setShowError(true), 200);
    } else {
      setShowError(false);
    }
  }

  function handleBlur(e, options = {}) {
    handleError();
    // options.toggle && toggleDrop();
  }

  function handleChange(e) {
    const data = { ...e, data: e };

    if (props.id) data.id = props.id;

    if (data.value === "EMPTY_VALUE") {
      return;
    }

    if (e.options && e.options.length > 0) {
      setOptions(e.options);
      setSelectedMain(e.label);
      return;
    }

    const fullValue = `${selectedMain} - ${e.label}`;
    setValue(fullValue);
    props.onChange && props.onChange({ ...data, fullValue });
    toggleDrop();

    if (mainOptions.some((option) => option.label === e.label)) {
      setOptions(mainOptions);
      setSelectedMain("");
    }
  }

  function toggleDrop(drop) {
    if (isObject(drop) && drop.target.localName === "svg") {
      setDropOpen(true);
      setStop(true);
    }
    const isBool = typeof drop === "boolean";
    setDropOpen(isBool ? drop : !dropOpen);
  }

  function renderOptions(arr) {
    return (
      <div>
        {/* Back arrow to main categories */}
        {selectedMain && (
          <div
            className="flex items-center cursor-pointer p-2 hover:bg-gray-100"
            onClick={() => {
              setOptions(mainOptions);
              setSelectedMain("");
            }}
          >
            <ChevronLeftIcon className="h-4 w-4 mr-2" />
            <span>Back to Main Categories</span>
          </div>
        )}
        {arr.map((item, i) => (
          <div key={i}>
            <div className={optionClasses} onClick={() => handleChange(item)}>
              {item.body || item.label}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function searchObject(obj, searchValue) {
    const valueKeys = Object.keys(obj).filter((valueKey) => {
      const search = props.searchKeys.filter((searchKey) => {
        const itemValue =
          (obj[searchKey] && obj[searchKey].toString().toUpperCase()) || "";

        return itemValue.includes(searchValue);
      });

      return search.length > 0;
    });

    return valueKeys.length > 0;
  }

  function handleSearch(e) {
    if (!props.search) return;
    const searchValue = e.target.value;
    setValue(searchValue);

    if (props.fullTextSearch) {
      return props.fullTextSearch(searchValue);
    }

    let newOptions = props.options.filter((option) => {
      const value = searchValue.toUpperCase();
      if (isObject(option.value)) {
        return searchObject(option.value, value);
      } else {
        const optionValue = option.value ? option.value.toUpperCase() : "";
        const optionLabel = option.label ? option.label.toUpperCase() : "";

        if (!value) return true;
        if (optionValue.includes(value)) return true;
        if (optionLabel.includes(value)) return true;
      }
      return false;
    });

    if (newOptions.length === 0) {
      const emptyOption = {
        label:
          props.emptyLabel &&
          (typeof props.emptyLabel === "string"
            ? props.emptyLabel
            : props.emptyLabel(searchValue) || "No items match this search"),
        value: "EMPTY_VALUE",
      };
      newOptions.push(emptyOption);
    }

    setOptions(newOptions);
  }

  function renderDropdown(options) {
    if (props.renderDropdown) {
      return props.renderDropdown({ options, handleChange });
    }
    return (
      <div className="shadow rounded-b max-h-56	overflow-y-scroll text-sm mx-0.5 bg-white absolute border border-t-0 w-web-fill z-20">
        {renderOptions(options)}
      </div>
    );
  }

  return (
    <div className={wrapperClasses}>
      {props.label && (
        <label htmlFor={props.id} className={labelClasses}>
          {props.label}
        </label>
      )}
      <div ref={ref} className="relative w-full">
        <input
          type="text"
          id={props.id}
          className={classes}
          placeholder={props.placeholder || "Select an option"}
          readOnly={!props.search}
          onClick={toggleDrop}
          value={value}
          onChange={handleSearch}
          disabled={props.loading}
          onBlur={(e) => handleBlur(e)}
          autoComplete={props.autoComplete || "off"}
        />
        {props.loading ? (
          <div className="flex absolute inset-y-0 right-0 items-center px-3">
            <Spinner size="sm" />
          </div>
        ) : (
          <div
            onClick={toggleDrop}
            className="cursor-pointer flex absolute inset-y-0 right-0 items-center px-3"
          >
            <Icon id="dropSVG" onClick={toggleDrop} className={iconClasses} />
          </div>
        )}
        {dropOpen && renderDropdown(options)}
        {props.error && showError && (
          <p className="mt-2 text-sm text-red-600">{props.error}</p>
        )}
      </div>
    </div>
  );
};

export default Select;
