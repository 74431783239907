import { getSearchParams } from "../../../utils/request";
import { api } from "../../api";

const locationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: ({ page = 1, limit = 20, ...rest }) => {
        const query = getSearchParams({
          legacy: false,
          page,
          limit,
          ...rest,
        });
        return `locations?${query}`;
      },
      transformResponse: (response) => response?.data,
      providesTags: (locations) =>
        locations?.docs
          ? [
              ...locations.docs.map(({ _id }) => ({
                type: "Locations",
                id: _id,
              })),
              { type: "Locations", id: "LIST" },
            ]
          : [{ type: "Locations", id: "LIST" }],
    }),
    addLocation: builder.mutation({
      query({ data, parentId }) {
        return {
          url: "locations",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Locations", id: "LIST" }],
    }),
    getLocation: builder.query({
      query: ({ id, ...rest }) => `locations/${id}?${getSearchParams(rest)}`,
      transformResponse: (response) => response.data,
      providesTags: (_result, _error, data) => {
        return [{ type: "Locations", id: data.id }];
      },
    }),
    updateLocation: builder.mutation({
      query({ locationId, data }) {
        console.log("locationId", locationId);
        console.log("data", data);
        return {
          url: `/locations/${locationId}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (_result, _error, { locationId }) => {
        return [
          { type: "Locations", id: locationId },
          { type: "Locations", id: "LIST" },
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
} = locationsApi;

export const locationSelectOptions = (state) => {
  return state.docs.map((obj) => {
    return {
      value: obj,
      label: obj.address?.fullAddress,
    };
  });
};
