export const categories = [
  {
    label: "Engine & Powertrain Parts",
    options: [
      { value: "engine_gas", label: "Engine (gas)" },
      { value: "engine_diesel", label: "Engine (diesel)" },
      { value: "engine_electric", label: "Engine (electric)" },
      { value: "alternator", label: "Alternator" },
      { value: "starter_motor", label: "Starter motor" },
      {
        value: "fuel_system",
        label: "Fuel system (fuel pumps, injectors, fuel filters)",
      },
      { value: "batteries", label: "Batteries (for electric forklifts)" },
      { value: "transmission", label: "Transmission" },
      { value: "hydraulic_pump", label: "Hydraulic pump" },
      {
        value: "cooling_system",
        label: "Cooling system (radiators, fans, thermostats)",
      },
    ],
  },
  {
    label: "Hydraulic System Components",
    options: [
      { value: "hydraulic_cylinders", label: "Hydraulic cylinders" },
      { value: "hydraulic_hoses", label: "Hydraulic hoses & fittings" },
      { value: "control_valves", label: "Control valves" },
      { value: "hydraulic_seals", label: "Hydraulic seals" },
      { value: "oil_filters", label: "Oil filters" },
      { value: "hydraulic_fluid", label: "Hydraulic fluid" },
    ],
  },
  {
    label: "Mast & Lifting Components",
    options: [
      { value: "mast", label: "Mast (single, duplex, or triplex)" },
      { value: "forks", label: "Forks (standard or custom)" },
      { value: "carriage", label: "Carriage" },
      { value: "chain_sprockets", label: "Chain & sprockets" },
      { value: "lift_cylinders", label: "Lift cylinders" },
      { value: "tilt_mechanism", label: "Tilt mechanism" },
    ],
  },
  {
    label: "Tires & Wheels",
    options: [
      { value: "pneumatic_tires", label: "Pneumatic tires" },
      { value: "solid_tires", label: "Solid tires" },
      { value: "cushion_tires", label: "Cushion tires" },
      { value: "drive_wheels", label: "Drive wheels" },
      { value: "load_wheels", label: "Load wheels" },
      { value: "tire_valve_stems", label: "Tire valve stems" },
    ],
  },
  {
    label: "Steering & Suspension",
    options: [
      { value: "steering_wheel", label: "Steering wheel" },
      { value: "steering_column", label: "Steering column" },
      { value: "steering_pumps", label: "Steering pumps" },
      { value: "suspension_springs", label: "Suspension springs" },
      { value: "shock_absorbers", label: "Shock absorbers" },
      { value: "axles", label: "Axles" },
    ],
  },
  {
    label: "Braking System",
    options: [
      { value: "brake_pads", label: "Brake pads/shoes" },
      { value: "brake_discs", label: "Brake discs/rotors" },
      { value: "brake_lines", label: "Brake lines" },
      { value: "master_cylinder", label: "Master cylinder" },
      { value: "brake_fluid", label: "Brake fluid" },
      { value: "parking_brake", label: "Parking brake" },
    ],
  },
  {
    label: "Electrical & Wiring Components",
    options: [
      { value: "wiring_harnesses", label: "Wiring harnesses" },
      { value: "fuses_relays", label: "Fuses & relays" },
      { value: "control_panels", label: "Control panels" },
      { value: "sensors", label: "Sensors" },
      {
        value: "lights",
        label: "Lights (headlights, taillights, hazard lights)",
      },
      { value: "horns", label: "Horns" },
      { value: "key_switches", label: "Key switches" },
    ],
  },
  {
    label: "Chassis & Frame Parts",
    options: [
      { value: "frame", label: "Frame" },
      { value: "crossmembers", label: "Crossmembers" },
      { value: "engine_mounts", label: "Engine mounts" },
      { value: "bumpers", label: "Bumpers" },
      { value: "side_panels", label: "Side panels" },
    ],
  },
  {
    label: "Operator Controls & Safety Equipment",
    options: [
      { value: "steering_wheel", label: "Steering wheel" },
      { value: "pedals", label: "Pedals (accelerator, brake, clutch)" },
      {
        value: "forklift_seat",
        label: "Forklift seat (with or without seatbelt)",
      },
      { value: "seat_cushions", label: "Seat cushions" },
      {
        value: "operator_restraint_system",
        label: "Operator restraint system (e.g., lap belts)",
      },
      { value: "operator_cabin", label: "Operator cabin or canopy" },
      { value: "mirrors", label: "Mirrors" },
      { value: "horn_safety_alarms", label: "Horn & safety alarms" },
    ],
  },
  {
    label: "Fuel & Exhaust System (for IC forklifts)",
    options: [
      { value: "fuel_tank", label: "Fuel tank" },
      { value: "exhaust_system", label: "Exhaust system" },
      {
        value: "catalytic_converter",
        label: "Catalytic converter (for emissions control)",
      },
      { value: "muffler", label: "Muffler" },
      { value: "fuel_filter", label: "Fuel filter" },
      {
        value: "emission_control_components",
        label: "Emission control components",
      },
    ],
  },
  {
    label: "Cooling & HVAC System",
    options: [
      { value: "radiator", label: "Radiator" },
      {
        value: "air_conditioning_compressor",
        label: "Air conditioning compressor (for operator comfort)",
      },
      { value: "heater", label: "Heater" },
      { value: "condenser", label: "Condenser" },
      { value: "blower_fan", label: "Blower fan" },
    ],
  },
  {
    label: "Lift Truck Attachments",
    options: [
      { value: "side_shifters", label: "Side shifters" },
      { value: "push_pull_attachments", label: "Push/pull attachments" },
      { value: "fork_positioners", label: "Fork positioners" },
      { value: "rotators", label: "Rotators" },
      { value: "clamps", label: "Clamps (paper roll, drum, etc.)" },
      { value: "bale_clamps", label: "Bale clamps" },
    ],
  },
  {
    label: "Maintenance & Service Parts",
    options: [
      { value: "oil_filters", label: "Oil filters" },
      { value: "air_filters", label: "Air filters" },
      {
        value: "grease_fittings",
        label: "Grease fittings & lubrication parts",
      },
      { value: "gaskets", label: "Gaskets" },
      { value: "belts_pulleys", label: "Belts & pulleys" },
    ],
  },
  {
    label: "Miscellaneous & Small Parts",
    options: [
      { value: "fasteners", label: "Fasteners (nuts, bolts, washers)" },
      { value: "bearings", label: "Bearings" },
      { value: "bushings", label: "Bushings" },
      { value: "clips_pins", label: "Clips and pins" },
    ],
  },
];

export const partCategoryOptions = [
  { value: "Alternator starter", label: "Alternator/Starter" },
  { value: "Brakes", label: "Brakes" },
  { value: "Cooling", label: "Cooling" },
  { value: "Drive axle", label: "Drive Axle" },
  { value: "Electrical", label: "Electrical" },
  { value: "Engine", label: "Engine" },
  { value: "Exhaust", label: "Exhaust" },
  { value: "Filters", label: "Filters" },
  { value: "Frame", label: "Frame" },
  { value: "Fuel", label: "Fuel" },
  { value: "Hydraulics", label: "Hydraulics" },
  { value: "Ignition", label: "Ignition" },
  { value: "Kits", label: "Kits" },
  { value: "Lights", label: "Lights" },
  { value: "Major Service", label: "Major Service" },
  { value: "Mast", label: "Mast" },
  { value: "Recommended Spare Parts", label: "Recommended Spare Parts" },
  { value: "Steering", label: "Steering" },
  { value: "Transmission", label: "Transmission" },
  { value: "Wheels", label: "Wheels" },
];
