import { getSearchParams } from "../../../utils";
import { api } from "../../api";

// PDF API
export const pdfApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generatePdf: builder.mutation({
      query: ({ data, ...rest }) => ({
        url: "/pdf/generate" + "?" + getSearchParams(rest),
        method: "POST",
        body: data,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return {
            url: URL.createObjectURL(blob),
            blob,
          };
        },
      }),
    }),
    getTemplates: builder.query({
      query: () => "/pdf/templates",
    }),
    saveTemplate: builder.mutation({
      query: (template) => ({
        url: "/pdf/templates",
        method: "POST",
        body: template,
      }),
      invalidatesTags: ["Templates"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGeneratePdfMutation,
  useGetTemplatesQuery,
  useSaveTemplateMutation,
} = pdfApi;
