import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetInventoryItemQuery,
  useDeleteInventoryItemMutation,
  useUpdateInventoryItemMutation,
} from "../../redux/services/inventory/inventoryApi";
import { toast } from "react-toastify";
import { Button, Card, Modal, Badge, EditableText } from "../../shared";
import CreateModal from "./CreateModal";
import { formatDate, renderAmount, isAuthorized } from "../../utils";
import { partCategoryOptions } from "../../constants/categories";

/**
 * InventoryShow component displays detailed information about a single inventory item
 * @component
 */
const InventoryShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const {
    data: inventoryItem,
    isLoading,
    isError,
  } = useGetInventoryItemQuery({ id });

  const [deleteInventoryItem] = useDeleteInventoryItemMutation();
  const [updateInventoryItem] = useUpdateInventoryItemMutation();

  if (isLoading)
    return <div className="flex justify-center p-8">Loading...</div>;
  if (isError)
    return (
      <div className="flex justify-center p-8">
        Error loading inventory item.
      </div>
    );

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "in stock":
        return "success";
      case "out of stock":
        return "error";
      case "coming soon":
        return "warning";
      case "discontinued":
        return "default";
      default:
        return "default";
    }
  };

  const handleDelete = async () => {
    try {
      await deleteInventoryItem(id).unwrap();
      toast.success("Item deleted successfully");
      navigate("/inventory");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to delete item");
    }
  };

  const handleUpdate = async (data) => {
    const update = {
      data: { [data.id]: data.value },
      id: id,
    };
    try {
      await updateInventoryItem(update).unwrap();
      toast.success("Item updated successfully");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to update item");
    }
  };

  const handleEditSuccess = (message) => {
    toast.success(message);
    setEditModalOpen(false);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-4xl font-bold text-gray-900">
            <EditableText
              id="name"
              value={inventoryItem.name}
              onSave={(e) => handleUpdate({ value: e.value, id: "name" })}
              displayText={(value) => (
                <span className="text-gray-900">{value}</span>
              )}
            />
          </h1>
          <p className="text-sm text-gray-500">
            Part Number:{" "}
            <EditableText
              id="partNumber"
              value={inventoryItem.partNumber}
              onSave={(e) => handleUpdate({ value: e.value, id: "partNumber" })}
              displayText={(value) => (
                <span className="text-gray-500">{value}</span>
              )}
            />
          </p>
        </div>
        {/* <div className="flex gap-4">
          {isAuthorized("owner,admin") && (
            <> */}
        {/* <Button onClick={() => setEditModalOpen(true)} type="primary">
                Edit Item
              </Button> */}
        {/* <Button onClick={() => setDeleteModalOpen(true)}>
                Delete Item
              </Button>
            </>
          )}
        </div> */}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Info Card */}
        <Card className="lg:col-span-2">
          <div className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-lg font-semibold">Item Details</h2>
              <EditableText
                id="status"
                value={inventoryItem.status}
                type="select"
                options={[
                  { value: "In Stock", label: "In Stock" },
                  { value: "Out of Stock", label: "Out of Stock" },
                  { value: "Coming Soon", label: "Coming Soon" },
                  { value: "Discontinued", label: "Discontinued" },
                ]}
                displayText={(value) => (
                  <Badge type={getStatusColor(value)}>{value}</Badge>
                )}
                onSave={(e) => handleUpdate({ value: e.value, id: "status" })}
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-500">Category</p>
                <EditableText
                  id="category"
                  value={inventoryItem.category}
                  type="select"
                  options={partCategoryOptions}
                  onSave={(e) =>
                    handleUpdate({ value: e.value, id: "category" })
                  }
                  displayText={(value) => (
                    <p className="font-medium">{value}</p>
                  )}
                />
              </div>
              <div>
                <p className="text-sm text-gray-500">Unit Price</p>
                <EditableText
                  id="unitPrice"
                  value={inventoryItem.unitPrice}
                  type="number"
                  onSave={(e) =>
                    handleUpdate({ value: e.value, id: "unitPrice" })
                  }
                  displayText={(value) => (
                    <p className="font-medium">{renderAmount(value)}</p>
                  )}
                />
              </div>
              <div>
                <p className="text-sm text-gray-500">Total Quantity</p>
                <EditableText
                  id="quantity"
                  value={inventoryItem.quantity}
                  type="number"
                  onSave={(e) =>
                    handleUpdate({ value: e.value, id: "quantity" })
                  }
                  displayText={(value) => (
                    <p className="font-medium">{value}</p>
                  )}
                />
              </div>
              <div>
                <p className="text-sm text-gray-500">Supplier</p>
                <p className="font-medium">
                  {inventoryItem.supplier?.name || "N/A"}
                </p>
              </div>
              {/* <div>
                <p className="text-sm text-gray-500">Supplier</p>
                <EditableText
                  id="supplier"
                  value={inventoryItem.supplier?.name}
                  onSave={(e) =>
                    handleUpdate({ value: e.value, id: "supplier" })
                  }
                  displayText={(value) => (
                    <p className="font-medium">{value || "N/A"}</p>
                  )}
                />
              </div> */}
            </div>

            {inventoryItem.description && (
              <div className="mt-6">
                <p className="text-sm text-gray-500 mb-2">Description</p>
                <EditableText
                  id="description"
                  value={inventoryItem.description}
                  type="text"
                  // layout={TextArea}
                  onSave={(e) =>
                    handleUpdate({ value: e.value, id: "description" })
                  }
                  displayText={(value) => (
                    <p className="text-gray-700">{value}</p>
                  )}
                />
              </div>
            )}
          </div>
        </Card>

        {/* Stock Levels Card */}
        {/* <Card>
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Stock Levels</h2>
            <div className="space-y-4"> */}
        {/* This will be populated with real location data in the future */}
        {/* {[
                { name: "Main Warehouse", quantity: 15, minimum: 5 },
                { name: "Secondary Storage", quantity: 8, minimum: 3 },
                { name: "Workshop", quantity: 2, minimum: 2 },
              ].map((location) => (
                <div key={location.name} className="border-b pb-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="font-medium">{location.name}</p>
                      <p className="text-sm text-gray-500">
                        Min. Required: {location.minimum}
                      </p>
                    </div>
                    <Badge
                      type={
                        location.quantity > location.minimum
                          ? "success"
                          : "warning"
                      }
                    >
                      {location.quantity} units
                    </Badge>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card> */}

        {/* Activity Timeline Card */}
        {/* <Card className="lg:col-span-3">
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-4">Recent Activity</h2>
            <div className="space-y-4">
              <div className="flex items-center gap-4">
                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                <p className="text-sm">
                  Created on {formatDate(inventoryItem.dateCreated)}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                <p className="text-sm">
                  Last updated on {formatDate(inventoryItem.dateUpdated)}
                </p>
              </div>
            </div>
          </div>
        </Card> */}
      </div>

      {/* Delete Confirmation Modal */}
      {/* <Modal
        visible={deleteModalOpen}
        setVisible={setDeleteModalOpen}
        title="Delete Inventory Item"
        footer={
          <div>
            <Button
              onClick={() => setDeleteModalOpen(false)}
              type="alternative"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} type="danger">
              Delete
            </Button>
          </div>
        }
      >
        <p>
          Are you sure you want to delete this inventory item? This action
          cannot be undone.
        </p>
      </Modal> */}
    </div>
  );
};

export default InventoryShow;
