import { useState, useCallback, useEffect } from "react";
import { useDebounceSearch } from "./useDebounceSearch";

export const useFetchAll = ({
  queryHook,
  defaultPageSize = 20,
  defaultFilters = [],
}) => {
  // Pagination state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  // Search functionality
  const { search, handleSearch, clearSearch } = useDebounceSearch();

  // Filters state
  const [filters, setFilters] = useState(defaultFilters);

  // Process filters
  const searchFilters = filters.reduce((acc, filter) => {
    if (filter.id === "transactionDate") {
      return {
        ...acc,
        startDate: filter.value?.start?.toISOString(),
        endDate: filter.value?.end?.toISOString(),
      };
    }
    return { ...acc, [filter.id]: filter.value };
  }, {});

  // Build query params
  const queryParams = {
    page: pageIndex + 1,
    limit: pageSize,
    search: search.useSearch,
    searchTerm: search.term,
    ...searchFilters,
  };

  // Execute the query
  const queryResult = queryHook(queryParams);

  // Handle global search
  const handleGlobalSearch = useCallback(
    (value) => {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      handleSearch(value);
    },
    [handleSearch]
  );

  // Prefetch next page if available
  const prefetchNext = useCallback(() => {
    if (
      queryResult.data?.hasNext &&
      queryResult.data.nextPage <= queryResult.data.totalPages &&
      queryResult.prefetch
    ) {
      queryResult.prefetch({
        page: queryResult.data.nextPage,
        limit: pageSize,
        search: search.useSearch,
        searchTerm: search.term,
        ...searchFilters,
      });
    }
  }, [
    queryResult.data,
    pageSize,
    search.useSearch,
    search.term,
    searchFilters,
    queryResult.prefetch,
  ]);

  useEffect(() => {
    prefetchNext();
  }, [prefetchNext]);

  return {
    // Pagination
    pagination: {
      pageIndex,
      pageSize,
      setPagination,
    },
    // Search
    search: {
      handleGlobalSearch,
      clearSearch,
      search,
      value: search.term,
    },
    // Filters
    filters: {
      filters,
      setFilters,
    },
    // Query results
    queryResult,
  };
};
