import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  fetchLocations,
  selectAllLocations,
  resetLocations,
  locationUpdated,
  locationDeleted,
  locationAdded,
  deleteLocations,
} from "../../redux/reducers/locations/locationsSlice";
import {
  resetClient,
  selectClient,
  updateClientLocation,
} from "../../redux/reducers/clients/clientSlice";
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
import Input from "../../shared/Input";
import useForm from "../../hooks/useForm";
import {
  addNewLocation,
  deleteLocation,
  updateLocation,
} from "../../redux/reducers/locations/locationSlice";
import TimeInput from "../../shared/TimeInput";
import Toggle from "../../shared/Toggle";
import Select from "../../shared/Select";
import { stateOptions } from "../../constants/select";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import Modal from "../../shared/Modal";
import { toast } from "react-toastify";
import { renderCustomColumnDefs } from "../../shared/Table/table.utils";
import LocationCreateModal from "./LocationCreateModal";
import { useDebounceSearch } from "../../hooks";
import { removeEmptyValues } from "../../utils";
import { usePrefetch } from "../../redux/api";

import { useGetLocationsQuery } from "../../redux/services/locations/locationsApi";
import { useFetchAll } from "../../hooks/useFetchAll";

const ClientLocationsIndex = ({ props }) => {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const clientStatus = useSelector((state) => state.client.status);
  const client = useSelector(selectClient);

  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const {
    pagination: { pageIndex, pageSize, setPagination },
    search: { handleGlobalSearch, value: searchValue },
    queryResult: { data, isLoading, isFetching },
  } = useFetchAll({
    queryHook: useGetLocationsQuery,
    defaultPageSize: 20,
    defaultFilters: [],
  });

  const newTableData = [
    {
      id: "name",
      required: true,
      cell: ({ row, getValue }) => {
        return (
          <div
            className="text-primary-500 hover:underline cursor-pointer"
            onClick={() => handleEdit(row.original)}
          >
            {getValue()}
          </div>
        );
      },
    },
    ...COLUMN_DEFINITIONS,
  ];

  const NEW_COLUMN_DEFINITIONS = renderCustomColumnDefs(newTableData);

  // useEffect(() => {
  //   if (client && isLoading) {
  //     dispatch(
  //       fetchLocations({ clientId: params.id, parentId: user.parentCompany })
  //     );
  //   }
  // }, []);
  const prefetchPage = usePrefetch("getLocations");

  const prefetchNext = useCallback(() => {
    prefetchPage({
      page: data.nextPage,
      limit: pageSize,
    });
  }, [prefetchPage, data?.nextPage, pageSize]);

  useEffect(() => {
    if (data?.hasNext && data.nextPage <= data.totalPages) {
      prefetchNext();
    }
  }, [data, pageIndex, prefetchNext]);

  useEffect(() => {
    return () => {
      // dispatch(resetLocations());
      // dispatch(resetClient());
    };
  }, []);

  async function handleEditClick(location, i, type) {
    showCreateEditModal();
    setEdit(true);
    setSelected({
      ...location,
      ...location.address,
      addressId: location.address._id,
      open: location.receivingHours?.open,
      close: location.receivingHours?.close,
      name: location.name,
      _id: location._id,
      edit: true,
    });
    // setFormInit({
    //   ...location,
    //   ...location.address.address,
    //   addressId: location.address._id,
    //   open: location.receivingHours?.open,
    //   close: location.receivingHours?.close,
    //   _id: location._id,
    // });
  }

  function handleEdit(item) {
    handleEditClick(item);
  }

  async function handleDelete(items) {
    const { parentCompany } = user;

    const res = await dispatch(
      deleteLocations({
        data: items.map((l) => l._id),
        parent: parentCompany,
        clientId: params.id,
      })
    ).unwrap();

    if (res.status === "success") {
      toast("Locations Deleted", {
        type: "success",
        toastId: "locationsDeleted",
      });
    }

    return res;
  }

  function showCreateEditModal() {
    setCreateModalVisible(true);
  }

  // CREATE MODAL
  // function closeCreateModal() {
  //   setSelected({ location: {} });
  //   setEdit(false);
  //   setCreateModalVisible(false);
  //   clearForm();
  // }

  function closeCreateModal() {
    setSelected({});
    setCreateModalVisible(false);
  }

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleGlobalSearch(value);
  }

  // if (isLoading || isFetching) return <Spinner />;
  return (
    <div className="overflow-hidden">
      <div className="flex flex-col">
        <div className="">
          {/* {locations.length === 0 ? (
            "No Locations"
          ) : ( */}
          <TableLayout
            mainAction={{ onClick: showCreateEditModal }}
            id="locations"
            loading={isLoading || isFetching}
            columns={NEW_COLUMN_DEFINITIONS}
            data={data?.locations || []}
            manualPagination={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            fullTextSearch={globalFilter}
            fullSelectedItem
          />
          {/* ) */}
          {/* } */}
        </div>
      </div>
      <LocationCreateModal
        visible={createModalVisible}
        setVisible={closeCreateModal}
        editData={selected}
        clientId={params.id}
      />
      {/* <Modal
        visible={createModalVisible}
        setVisible={setCreateModalVisible}
        onClose={closeCreateModal}
        title={createModalTitle}
        footer={createModalFooter}
        content={createModalContent}
        size="lg"
        footerPosition="right"
      /> */}
      {/* <Modal
        visible={deleteModalVisible}
        onClose={closeDeleteModal}
        title={deleteModalTitle}
        footer={deleteModalFooter}
        content={deleteModalContent}
        size="md"
        footerPosition="right"
      /> */}
    </div>
  );
};

export default ClientLocationsIndex;
