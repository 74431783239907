import React from "react";
import { Button } from "../../../shared";
import { useConfig } from "../../../hooks";
import { useGeneratePdfMutation } from "../../../redux/services/pdf/pdfApi";
import { format } from "date-fns";
import { toast } from "react-toastify";

const InvoiceGeneratorButton = ({
  invoice,
  button: ButtonComponent,
  filename = null,
  download = true,
  previewId = "pdf-preview",
  ...props
}) => {
  const { appType } = useConfig();

  const [generatePdf, { isLoading }] = useGeneratePdfMutation();

  const generatePDF = async () => {
    try {
      const result = await generatePdf({
        data: {
          ref: invoice,
          jobName: invoice.invoiceId,
          isQuoteMode: false,
          type: "Invoice",
        },
      }).unwrap();

      const fileName = `${invoice.invoiceId}_${format(
        new Date(),
        "M-d-yy"
      )}.pdf`;

      // Verify the generated PDF
      if (!result?.blob || result.blob.size === 0) {
        toast.error("PDF generation failed - please try again");
        return;
      }

      if (download) {
        // Create download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(result.blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        return;
      }

      // Display PDF in iframe
      const pdfUrl = URL.createObjectURL(result.blob);
      document.getElementById(previewId).src = pdfUrl;
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      toast.error("Failed to generate PDF - please try again");
    }
  };

  // If a custom button component is provided, clone it with the onClick handler
  if (ButtonComponent) {
    return React.cloneElement(ButtonComponent, {
      onClick: generatePDF,
    });
  }

  // Default button if no custom button is provided
  return (
    <Button {...props} onClick={generatePDF}>
      Generate PDF
    </Button>
  );
};

export default InvoiceGeneratorButton;
