import React, { useCallback, useState, useEffect } from "react";
import {
  useGetInventoryItemsQuery,
  useDeleteInventoryItemMutation,
  useUpdateInventoryItemMutation,
} from "../../redux/services/inventory/inventoryApi";
import CreateModal from "./CreateModal";
import { Button } from "../../shared";
import TableLayout from "../../layouts/TableLayout/NewTableLayout";
import { toast } from "react-toastify";
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS } from "./table.config";
import { useDebounceSearch } from "../../hooks";
import { isAuthorized } from "../../utils/authority";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const InventoryIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { search, handleSearch } = useDebounceSearch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const sendSelectedItems = useCallback((value) => setSelectedItems(value), []);

  // const [filters, setFilters] = useState([]);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  // Add this function to handle the row click

  useEffect(() => {
    if (selectedItem) {
      console.log("Selected Item Updated:", selectedItem);

      setCreateModalOpen(true);
    }
  }, [selectedItem]);
  // const searchFilters = filters.reduce((a, v) => {
  //   if (v.id === "quantity") {
  //     return {
  //       ...a,
  //       startDate: v.value?.start.toISOString(),
  //       endDate: v.value?.end.toISOString(),
  //     };
  //   }
  //   return { ...a, [v.id]: v.value };
  // }, {});

  const NEW_COLUMN_DEFINITIONS = [
    {
      id: "name",
      accessorKey: "name",
      visible: true,
      minWidth: 120,
      cell: ({ getValue, row }) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`/inventory/${row.original._id}`);

            // setSelectedItem(row.original);
          }}
        >
          {getValue()}
        </Button>
      ),
    },
    ...COLUMN_DEFINITIONS,
  ];

  const { data, isLoading, isFetching, isError, error } =
    useGetInventoryItemsQuery({
      page: pageIndex + 1,
      limit: pageSize,
      search: search.useSearch,
      searchTerm: search.term,
    });

  const cannotCreateOrEdit = !isAuthorized("owner, admin");

  // Handle modal close
  const handleCloseModal = () => {
    setCreateModalOpen(false);
    setSelectedItem(null);
  };
  // Handle item creation success
  const handleCreateSuccess = (message) => {
    toast.success(message);
    handleCloseModal();
  };

  // Handle item creation error
  const handleCreateError = (message) => {
    toast.error(message);
  };

  // Handle delete function
  const handleDelete = async (id) => {
    try {
      await deleteInventoryItem(id).unwrap();
      toast.success("Item deleted successfully");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to delete item");
    }
  };

  // Handle edit function
  const handleEdit = async (id, data) => {
    try {
      await updateInventoryItem({ id, data }).unwrap();
      toast.success("Item updated successfully");
    } catch (error) {
      toast.error(error?.data?.message || "Failed to update item");
    }
  };

  function globalFilter(value) {
    setPagination({ pageIndex: 0, pageSize });
    handleSearch(value);
  }

  if (isError) {
    return (
      <div>
        Error: {error?.data?.message || "Failed to load inventory items"}
      </div>
    );
  }

  return (
    <div>
      <TableLayout
        id="inventory"
        title={t("inventory.main")}
        columns={NEW_COLUMN_DEFINITIONS}
        data={data?.inventory}
        loading={isLoading || isFetching}
        pagination={data}
        pageIndex={pageIndex}
        pageSize={pageSize}
        mainAction={{ onClick: () => setCreateModalOpen(true) }}
        onPaginationChange={setPagination}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        fullTextSearch={globalFilter}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        hideActions={cannotCreateOrEdit}
        hideMainAction={cannotCreateOrEdit}
        globalSearchFilters={["name", "quantity"]}
        sendSelectedItems={sendSelectedItems}
        // handleDeleteClick={handleDeleteClick}
        // clearSelectedItems={deselectItems}
        // filters={filterTypes}
        // onFiltersChange={setFilters}
        hideCheckbox={!isAuthorized("owner,admin")}
      />

      {createModalOpen && (
        <CreateModal
          modalOpen={createModalOpen}
          setModalOpen={setCreateModalOpen}
          onSuccess={handleCreateSuccess}
          onError={handleCreateError}
          initialData={selectedItem}
        />
      )}
    </div>
  );
};

export default InventoryIndex;
