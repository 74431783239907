import Avatar from "../../../shared/Avatar";
import Button from "../../../shared/Button";
import {
  EnvelopeIcon,
  PhoneIcon,
  BuildingOfficeIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import Modal from "../../../shared/Modal";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Input from "../../../shared/Input";
import useForm from "../../../hooks/useForm";
import { updateClient } from "../../../redux/reducers/clients/clientSlice";
import { useAuth } from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import EditableText from "../../../shared/EditableText";
import Select from "../../../shared/Select";
import { stateOptions } from "../../../constants/select";

const ClientCard = ({ client }) => {
  const { user } = useAuth();
  const { form, updateForm, clearForm, setFormInit } = useForm();
  const [addressModalVisible, setAddressModalVisible] = useState(false);
  const [addressModalType, setAddressModalType] = useState("Edit Address");
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();

  function handleAddressEdit() {
    setFormInit(client.address);
    setAddressModalVisible(true);
  }

  function onFormChange({ id, value }) {
    updateForm({ id, value });
  }

  function onSave(data) {
    dispatch(
      updateClient({ data, parentId: user.parentCompany, clientId: client._id })
    );
  }

  async function onAddressSave(data) {
    const res = await dispatch(
      updateClient({ data, parentId: user.parentCompany, clientId: client._id })
    ).unwrap();
    if (res.status === "success") {
      closeAddressModal();
    }
  }

  function closeAddressModal() {
    setAddressModalVisible(false);
    clearForm();
  }

  const AddressModalTitle = addressModalType;

  const AddressModalFooter = (
    <div>
      <Button className="mr-3" onClick={closeAddressModal} type="alternative">
        Cancel
      </Button>
      <Button
        onClick={() => onAddressSave({ ...form, updateAddress: true })}
        type="primary"
      >
        Submit
      </Button>
    </div>
  );

  const AddressModalContent = (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-2">
        <Input
          id="address"
          size="md"
          value={form.address}
          label="Address"
          placeholder="Address"
          onChange={updateForm}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="suite"
          value={form.suite}
          label="Suite"
          placeholder="Suite"
          onChange={updateForm}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="city"
          value={form.city}
          label="City"
          placeholder="City"
          onChange={updateForm}
        />
      </div>
      <div className="col-span-1">
        <Select
          id="state"
          value={form.state}
          label="State/Province"
          placeholder="State/Province"
          onChange={updateForm}
          search
          options={stateOptions}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="country"
          value={form.country}
          label="Country"
          placeholder="Country"
          onChange={updateForm}
        />
      </div>
      <div className="col-span-1">
        <Input
          id="zipcode"
          value={form.zipcode}
          label="Zipcode"
          placeholder="Zipcode"
          onChange={updateForm}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="">
        <div className="flex md:flex-col justify-evenly items-center my-5">
          <div className="relative">
            <Avatar
              size="lg"
              src={client.avatar}
              alt={`${client.name} Avatar`}
            />
            {/* <div className="bg-gray-300 absolute bottom-0 w-full text-center">
            Edit
          </div> */}
          </div>

          <div className="flex flex-col md:items-center items-start md:mt-3 space-y-3">
            {/* <h5 className="text-xl font-medium text-gray-900">{client.name}</h5> */}
            <ClientInfoEditable
              id="email"
              form={form}
              onChange={onFormChange}
              value={client.email}
              clearForm={clearForm}
              save={onSave}
            />
            <ClientInfoEditable
              id="phone"
              form={form}
              onChange={onFormChange}
              value={client.phone}
              clearForm={clearForm}
              save={onSave}
            />
            <ClientInfoEditable
              id="coowner"
              form={form}
              onChange={onFormChange}
              value={client.coowner}
              clearForm={clearForm}
              save={onSave}
              displayText={(v) => <span>Co-owner: {v}</span>}
            />
            <div className="flex items-start my-2">
              <MapPinIcon className="stroke-2 h-6 w-6" />
              <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className="flex items-center"
              >
                <div className="text-sm font-medium text-gray-900 w-full">
                  <div className="flex justify-between items-center w-full space-x-1">
                    <div>
                      <div>{client.address?.address}</div>
                      <div>
                        {client.address?.city}, {client.address?.state},{" "}
                        {client.address?.zipcode}
                      </div>
                    </div>
                    <div className="h-3 w-3">
                      {/* {(!client.address?.fullAddress || hovered) && (
                        <PencilIcon
                          onClick={handleAddressEdit}
                          className="h-3 w-3 text-primary-600 hover:text-primary-700 cursor-pointer"
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={addressModalVisible}
        setVisible={setAddressModalVisible}
        onClose={closeAddressModal}
        title={AddressModalTitle}
        footer={AddressModalFooter}
        content={AddressModalContent}
        size="md"
        footerPosition="right"
      />
    </>
  );
};

const ClientInfoEditable = ({
  id,
  value,
  displayText,
  onChange,
  onClick,
  clearForm,
  save,
}) => {
  const Icon =
    id === "email"
      ? EnvelopeIcon
      : id === "phone"
      ? PhoneIcon
      : BuildingOfficeIcon;

  function onSave(e) {
    save(e.data);
  }
  return (
    <div className="flex items-center my-2">
      <Icon className="stroke-2 h-6 w-6" />
      <EditableText
        className="ml-1"
        iconClasses="h-3 w-3"
        id={id}
        value={value}
        onSave={onSave}
        onClick={onClick}
        displayText={displayText}
        type="text"
      />
    </div>
  );
};

export default ClientCard;
